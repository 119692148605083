import React from "react";

import { ReactComponent as TrashIcon } from  "../assets/icons/trash.svg";
import { NewsletterEditionLink } from "../types/newsletterEdition";

interface UrlInputFormProps {
  urlObjects: NewsletterEditionLink[];
  handleUrlsChange: (newUrls: NewsletterEditionLink[]) => void;
  oldUrls: string[];
  placeholder: string;
  instructions: boolean;
}

const UrlInputForm: React.FC<UrlInputFormProps> = ({
  urlObjects,
  handleUrlsChange,
  oldUrls,
  placeholder,
  instructions,
}) => {
  const handleUrlChange = (index: number, value: string) => {
    const newUrlObjects = [...urlObjects];
    newUrlObjects[index].url = value;
    handleUrlsChange(newUrlObjects);

    // if the input is deleted, remove it from the array
    if (value.trim() === "" && newUrlObjects.length > 1) {
      newUrlObjects.splice(index, 1);
      handleUrlsChange(newUrlObjects);
    }

    // if the last input has content, add a new empty input
    if (index === urlObjects.length - 1 && value.trim() !== "") {
      handleUrlsChange([...newUrlObjects, { url: "" }]);
    }
  };

  const handleDelete = (index: number) => {
    // set empty url for the index clicked
    handleUrlChange(index, "");
  }

  const handleInstructionsChange = (index: number, value: string) => {
    const newUrls = [...urlObjects];
    newUrls[index].instructions = value;
    handleUrlsChange(newUrls)
  }

  return (
    <div className="url-input-container">
      {urlObjects?.map((urlObject: NewsletterEditionLink, index: number) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <input
            type="url"
            value={urlObject.url}
            onChange={e => handleUrlChange(index, e.target.value)}
            placeholder={placeholder}
            className={`url-input ${oldUrls.includes(urlObject.url) ? 'disabled' : ''}`}
            readOnly={oldUrls.includes(urlObject.url)}
          />

          { instructions &&
            <input
              type="url"
              value={urlObject.instructions || ""}
              onChange={e => handleInstructionsChange(index, e.target.value)}
              placeholder="Add instructions..."
              className={`url-input ${oldUrls.includes(urlObject.url) ? 'disabled' : ''}`}
              readOnly={oldUrls.includes(urlObject.url)}
            />
          }

          { Boolean(urlObject.url) &&
            <span
              className="trash-icon"
              onClick={() => handleDelete(index)}
            >
              <TrashIcon />
            </span>
          }
        </div>
      ))}
    </div>
  );
};

export default UrlInputForm;
