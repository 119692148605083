// src/components/Onboarding/steps/Step3.tsx
import React, { useEffect, useState, useRef } from "react";
import { OnboardingFormProps } from "../Types";
import { ReactComponent as InfoIcon } from "../../../assets/icons/Info.svg";
import SidePanel from "../../SidePanel";
import AudienceOfOneExamples from "./AudienceOfOneExamples";

const AudienceOfOne: React.FC<OnboardingFormProps> = ({formData, onChange}) => {
  const [isExamplesSidePanelOpen, setIsExamplesSidePanelOpen] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="primary-input-container">
      <h2>Ideal Reader</h2>
      <p>
        Describe your ideal reader. Be as specific as possible (age, job,
        location, industry).
      </p>
      <textarea
        ref={inputRef}
        value={formData.audienceOfOne}
        onChange={e => onChange("audienceOfOne", e.target.value)}
        placeholder="A US-based Chief Information Officer (CIO) interested in buying enterprise security software, with 10+ years of experience and working at a company with > $10M annual revenue."
        className="primary-input"
        rows={8}
        cols={50}
        style={{
          font: "inherit",
          border: "2px solid #000000",
          borderRadius: "4px",
          resize: "none",
          padding: "12px",
        }}
      />

      <span style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          textDecoration: "underline",
          cursor: "pointer",
          marginTop: "16px",
        }}
        onClick={() => setIsExamplesSidePanelOpen(true)}
      >
        See Examples
        <InfoIcon style={{ marginLeft: "8px" }} />
      </span>

      <SidePanel isOpen={isExamplesSidePanelOpen} onClose={() => setIsExamplesSidePanelOpen(false)}>
        <AudienceOfOneExamples />
      </SidePanel>
    </div>
  );
};

export default AudienceOfOne;
