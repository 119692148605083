import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import onboardingService from "../services/onboardingService";
import { ReactComponent as DraggableDotsIcon } from "../assets/icons/draggableDots.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from  "../assets/icons/trash.svg";
import { BlueprintSection } from "./Onboarding/Types";
import SidePanel from "./SidePanel";
import BlueprintSectionPanel from "./BlueprintSectionPanel";
import { BLUEPRINT_SECTION_EXAMPLES, SECTION_FORMAT_TO_DISPLAY_NAME } from "./Onboarding/Constants";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";


const Blueprint: React.FC = () => {
  const [sections, setSections] = useState<BlueprintSection[]>([]);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();

  const fetchBlueprint = async () => {
    return onboardingService.getOnboardingBlueprint();
  };

  useEffect(() => {
    fetchBlueprint().then(blueprint => {
      setSections(blueprint?.raw_info || []);
    });
  }, []);

  const saveBlueprint = async (sections: BlueprintSection[]) => {
    return onboardingService.saveOnboardingBlueprint(sections);
  };

  const addSection = () => {
    const contentType = Object.keys(BLUEPRINT_SECTION_EXAMPLES)[0];
    const format = Object.keys(BLUEPRINT_SECTION_EXAMPLES[contentType])[0];
    const newSection: BlueprintSection = {
      title: 'New Section',
      content_type: contentType,
      format: format,
    };

    const newSections = [...sections, newSection];

    // add the new section, then open the side panel for it
    saveBlueprint(newSections).then(blueprint => {
      setSections(blueprint.raw_info);
      setActiveIndex(newSections.length - 1);
      setIsSidePanelOpen(true);
    });
  };

  const updateSection = (index: number, updatedSection: Partial<BlueprintSection>) => {
    const oldSections = sections;
    const newSections = oldSections.map((section: BlueprintSection, i: number) =>
      i === index ? { ...section, ...updatedSection } : section
    );

    saveBlueprint(newSections).then(blueprint => {
      setSections(blueprint.raw_info);
    });
  };

  const deleteSection = (indexToRemove: number) => {
    const newSections = [
      ...sections.slice(0, indexToRemove),
      ...sections.slice(indexToRemove + 1),
    ];

    saveBlueprint(newSections).then(blueprint => {
      setSections(blueprint.raw_info);
    });
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    // return if outside if outside area or same position
    if (!destination) return;
    if (destination.index === source.index) return;

    const reorderedSections = Array.from(sections);
    const [movedItem] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, movedItem);

    setSections(reorderedSections);
    saveBlueprint(reorderedSections);
  };

  return (
    <div
      onClick={() => {
        if (isSidePanelOpen) {
          setIsSidePanelOpen(false);
        }
      }}
    >
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        minWidth: "880px",
      }}>
        <h1>Blueprint</h1>

        <div style={{ display: "inline-block", marginLeft: "auto" }}>
          <button
            className="tertiary-info-button"
            style={{
              cursor: "pointer",
              marginRight: "12px",
            }}
            onClick={(e) => {
              e.stopPropagation(); // short-circuit the parent's `onClick` behavior

              navigate("/onboarding");
            }}
          >
            Rebuild blueprint
          </button>

          <button
            className="primary-button"
            onClick={(e) => {
              e.stopPropagation(); // short-circuit the parent's `onClick` behavior

              addSection();
            }}
          >
            Add section
          </button>
        </div>
      </div>

      <p style={{ marginTop: "0", marginBottom: "0", textAlign: "left" }}>
        Edit the blueprint of your newsletter. Aimply will fill out this template for you each week.
      </p>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">

          {(provided: any) => (
            <ul
              className="newsletter-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              { sections.map((section: BlueprintSection, index) => (
                <Draggable key={section.title} draggableId={section.title} index={index}>

                  {(provided: any) => (
                    <li
                      className="newsletter-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        marginTop: "16px",
                        cursor: "pointer",
                        ...provided.draggableProps.style, // apply styles from the library
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // short-circuit the parent's `onClick` behavior

                        setActiveIndex(index);
                        setIsSidePanelOpen(true);
                      }}
                    >
                      <div
                        className="newsletter-item-content-left"
                        style={{ flexDirection: "row", justifyContent: "center" }}
                      >
                        <DraggableDotsIcon
                          aria-label="Draggable"
                          style={{
                            width: "1.5em",
                            height: "2em",
                            marginLeft: "4px",
                            cursor: "grab",
                          }}
                        />
                        <span
                          className="newsletter-title"
                          style={{
                            paddingLeft: "8px",
                          }}
                        >
                          {section.title}
                        </span>
                      </div>

                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}>
                        <span className="list-tag-gray">
                          {section.content_type}
                        </span>

                        <span className="list-tag-gray">
                          {SECTION_FORMAT_TO_DISPLAY_NAME[section.format] || section.format}
                        </span>

                        <EditIcon
                          aria-label="Edit"
                          style={{
                            width: "2em",
                            height: "2em",
                            marginRight: "-8px",
                          }}
                        />

                        <TrashIcon
                          aria-label="Delete"
                          style={{
                            width: "2em",
                            height: "2em",
                            marginRight: "12px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // short-circuit the parent's `onClick` behavior

                            const confirmed = window.confirm(
                              "Are you sure you want to delete this section? This action cannot be undone."
                            );

                            if (confirmed) {
                              deleteSection(index);
                            }
                          }}
                        />
                      </div>
                    </li>
                  )}

                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}

        </Droppable>
      </DragDropContext>

      <div onClick={(e) => e.stopPropagation()}>
        <SidePanel isOpen={isSidePanelOpen} onClose={() => setIsSidePanelOpen(false)}>
          <BlueprintSectionPanel sections={sections} index={activeIndex} updateSection={updateSection} />
        </SidePanel>
      </div>
    </div>
  );
};

export default Blueprint;
