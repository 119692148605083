import React, {useEffect, useState} from "react";
import {
  signup,
  registerWithPasswordlessAuth,
} from "../services/authService";
import {useNavigate} from "react-router-dom";
import {getWebsiteUrl} from "../services/environmentService";
import {AxiosError} from "axios";
import {selectAuthenticated} from "../store/slices/authUserSlice";
import { useSelector } from "react-redux";

// TODO load from server side
const HAS_ONBOARDED = false;

const Signup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>("");

  // either show a single email input for passwordless auth or the email/password combo input
  const [shouldUsePasswordlessAuth, setShouldUsePasswordlessAuth] =
    useState(true);
  const [message, setMessage] = useState<string>("");
  const [
    isPasswordlessAuthButtonDisabled,
    setIsPasswordlessAuthButtonDisabled,
  ] = useState(false);
  const isAuthenticated = useSelector(selectAuthenticated);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if (HAS_ONBOARDED) {
        navigate("/analytics");
      } else {
        navigate("/onboarding");
      }
    }
  }, [navigate, isAuthenticated]);

  const handleSignupError = (error: AxiosError) => {
    console.error('Error', error);
    setError("An unexpected error occurred");
  };

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();

    const redirectPage = "/onboarding";

    if (!shouldUsePasswordlessAuth) {
      signup(email, password)
        .then(() => {
          navigate(redirectPage);
        })
        .catch(handleSignupError);
      return;
    }

    const redirectUrl = `${getWebsiteUrl()}${redirectPage}`;
    registerWithPasswordlessAuth({email, redirectUrl})
      .then(() => {
        setError("");
        setMessage(
          "We've sent you an email! Click on the link in the email to continue!",
        );
        setIsPasswordlessAuthButtonDisabled(true);
      })
      .catch(handleSignupError);
  };

  return (
    <form onSubmit={handleSignup}>
      <h1>Sign up</h1>
      <div>
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          className="primary-input"
          placeholder="Email"
          required
        />
      </div>
      {!shouldUsePasswordlessAuth ? (
        <div>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="primary-input"
            placeholder="Password"
            required
          />
        </div>
      ) : (
        <></>
      )}
      {error && <p style={{color: "red"}}>{error}</p>}
      {message && <p style={{color: "green"}}>{message}</p>}
      {shouldUsePasswordlessAuth ? (
        <p>
          We'll send you a magic link to sign up. You can also{" "}
          <a href="#" onClick={() => setShouldUsePasswordlessAuth(false)}>
            sign up by creating a password.
          </a>
        </p>
      ) : (
        <>
          <a href="#" onClick={() => setShouldUsePasswordlessAuth(true)}>
            <p>Get a magic link to sign up</p>
          </a>
        </>
      )}

      {shouldUsePasswordlessAuth ? (
        <button
          type="submit"
          className="primary-button"
          disabled={isPasswordlessAuthButtonDisabled}>
          {isPasswordlessAuthButtonDisabled
            ? "Check your email for a link!"
            : "Email me a link"}
        </button>
      ) : (
        <button type="submit" className="primary-button">
          Sign up
        </button>
      )}
    </form>
  );
};

export default Signup;
