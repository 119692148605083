import React from "react";

const Help: React.FC = () => {
  return (
      <div>
        <h1>Help</h1>
        <div className="primary-input-container"
             style={{maxWidth: "600px"}}
        >
          <p style={{marginTop: "0"}}>
            If you have any questions or feedback, you can always email <a href="mailto:evan@aimply.io">evan@aimply.io</a> or
            text +1 614-316-4995.
          </p>
          <p>We'll do our best to get back to you same day and at worst case in 48 hours.</p>
          <p>
            Thanks for using v1 of Aimply!
          </p>
        </div>
      </div>
  );
};

export default Help;
