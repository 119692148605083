import React, {useState, useEffect} from "react";
import UrlInputForm from "./UrlInputForm";
import DisclosureText from "./DisclosureText";
import campaignService from "../services/campaignService";
import {
  NewsletterEdition,
  NewsletterEditionLink,
} from "../types/newsletterEdition";

export interface EditionLinksProps {
  newsletterEditionId?: string;
}

const EditionLinks: React.FC<EditionLinksProps> = ({ newsletterEditionId }) => {
  const [urlObjects, setUrlObjects] = useState<NewsletterEditionLink[]>([{ url: "" }]);
  const [updatedLinksSuccess, setUpdatedLinksSuccess] = useState(false);

  const [oldUrls, setOldUrls] = useState([""]);

  const handleUrlsChange = (newUrlObjects: NewsletterEditionLink[]) => {
    setUrlObjects(newUrlObjects);
  };

  const updateNewsletterEditionLinks = async (urlObjects: NewsletterEditionLink[]) => {
    const newsletterEditionLinks: NewsletterEditionLink[] = urlObjects
      .filter(urlObject => Boolean(urlObject.url))
      .map(urlObject => {
        return { url: urlObject.url }
      });

    try {
      setUpdatedLinksSuccess(false);
      const newsletterEdition =
        await campaignService.updateNewsletterEditionLinks(
          newsletterEditionId!,
          newsletterEditionLinks,
        );
      setUpdatedLinksSuccess(true);

      // clear success banner so it's not sticky
      setTimeout(() => {
        setUpdatedLinksSuccess(false);
      }, 1500);

      const newsletterEditionUrls = newsletterEdition.newsletter_edition_links.map(
        link => link.url,
      );
      setUrlObjects([...newsletterEdition.newsletter_edition_links, { url: "" }]); // account for next empty url input
      setOldUrls(newsletterEditionUrls); // submitted urls are now "old"
    } catch (e) {
      console.error("Failed to update links", e);
    }
  };

  const fetchNewsletterEdition = async (
    newsletterEditionId: string,
  ): Promise<NewsletterEdition> => {
    return campaignService.getNewsletterEdition(newsletterEditionId);
  };

  useEffect(() => {
    if (!newsletterEditionId) return;
    fetchNewsletterEdition(newsletterEditionId).then(newsletterEdition => {
      const newsletterEditionUrls = newsletterEdition.newsletter_edition_links.map(
        link => link.url,
      );
      setUrlObjects([...newsletterEdition.newsletter_edition_links, { url: "" }]); // account for next empty url input
      setOldUrls(newsletterEditionUrls);
    });
  }, [newsletterEditionId]);

  return (
    <div>
      <h1>Links</h1>

      <div className="info-bubble">
        💡
        <span style={{marginLeft: "8px"}}>
          Specify links you'd like us to include in your <b>next edition</b>.
        </span>
        <br />
        <br />
        <div style={{paddingLeft: "22px"}}>
          Good examples include:
          <ul style={{marginTop: "8px", marginBottom: "4px"}}>
            <li>A new feature announcement</li>
            <li>A recent job opening</li>
            <li>An industry event</li>
          </ul>
        </div>
      </div>

      {updatedLinksSuccess && (
        <div
          style={{
            backgroundColor: "#e0f7df",
            color: "#2e7d32",
            border: "1px solid #2e7d32",
            borderRadius: "8px",
            padding: "8px 16px",
            marginBottom: "8px",
            maxWidth: "500px",
          }}>
          ✅
          <span style={{marginLeft: "12px"}}>Links successfully updated!</span>
        </div>
      )}

      <UrlInputForm
        urlObjects={urlObjects}
        handleUrlsChange={handleUrlsChange}
        oldUrls={oldUrls}
        placeholder="https://openai.com/index/chatgpt"
        instructions={false}
      />

      <button
        className="primary-button"
        onClick={_ => updateNewsletterEditionLinks(urlObjects)}>
        Save
      </button>

      <DisclosureText />
    </div>
  );
};

export default EditionLinks;
