import React, {ReactElement} from "react";

interface NavItemProps {
  icon: ReactElement<any, any>;
  text: string;
  isActive: boolean;
  onClick: () => void;
}

const NavItem: React.FC<NavItemProps> = ({icon, text, isActive, onClick}) => (
  <li
    id={`Sidebar-${text}`}
    className={`sidebar-item ${isActive ? "active" : ""}`}
    onClick={onClick}
    style={{display: "flex", alignItems: "center"}} // Flexbox to align items inline
  >
    <span style={{display: "inline-flex", alignItems: "center"}}>
      {React.cloneElement(icon, {style: {fontSize: "1em", flexShrink: 0}})}
    </span>
    <span style={{marginLeft: "8px"}}>{text}</span>{" "}
    {/* Add some space between the icon and the text */}
  </li>
);

export default NavItem;
