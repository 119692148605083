import React from "react";

const GOOD = "A US-based Chief Information Officer (CIO) interested in buying enterprise security software."
const BETTER = "A US-based Chief Information Officer (CIO) interested in buying enterprise security software, with 10+ years of experience."
const BETTER_PARTS = BETTER.split(',');
const BEST = "A US-based Chief Information Officer (CIO) interested in buying enterprise security software, with 10+ years of experience and working at a company with > $10M annual revenue."
const BEST_PARTS = BEST.split('and');

const AudienceOfOneExamples: React.FC = () => {
  return (
    <div>
      <h1>
        Examples
      </h1>

      <p className="audience-of-one-example-text">
        <b>Good: </b>{GOOD}
      </p>

      <p className="audience-of-one-example-text">
        <b>Better: </b>{BETTER_PARTS[0]}<em>{`, ${BETTER_PARTS[1]}`}</em>
      </p>

      <p className="audience-of-one-example-text">
        <b>Best: </b>{BEST_PARTS[0]}<em>{`and ${BEST_PARTS[1]}`}</em>
      </p>
    </div>
  )
};

export default AudienceOfOneExamples;
