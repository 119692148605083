import React, {useState, useEffect} from "react";
import UrlInputForm from "./UrlInputForm";
import DisclosureText from "./DisclosureText";
import campaignService from "../services/campaignService";
import { NewsletterEditionLink } from "../types/newsletterEdition";

const Sources: React.FC = () => {
  const [urlObjects, setUrlObjects] = useState<NewsletterEditionLink[]>([{ url: "" }]);
  const [updatedSourcesSuccess, setUpdatedSourcesSuccess] = useState(false);

  const [oldUrls, setOldUrls] = useState([""]);

  const handleUrlsChange = (newUrlObjects: NewsletterEditionLink[]) => {
    setUrlObjects(newUrlObjects);
  };

  const updateNewsletterSources = async (sources: NewsletterEditionLink[]) => {
    const newsletterSources = sources
      .filter(source => Boolean(source))
      .map(source => {
        return { url: source.url, instructions: source.instructions }
      });

    try {
      setUpdatedSourcesSuccess(false);
      const sources = await campaignService.updateNewsletterSources(newsletterSources);
      setUpdatedSourcesSuccess(true);

      // clear success banner
      setTimeout(() => {
        setUpdatedSourcesSuccess(false);
      }, 1500);

      const newsletterSourceUrls = sources.map(source => source.url);
      setUrlObjects([...sources, { url: "" }]); // account for next empty url input
      setOldUrls(newsletterSourceUrls); // submitted urls are now "old"
    } catch (e) {
      console.error("Failed to update sources", e);
    }
  };

  const fetchSources = async () => {
    return campaignService.getNewsletterSources();
  };

  useEffect(() => {
    fetchSources().then(sources => {
      const newsletterSourceUrls = sources.map(source => source.url);
      setUrlObjects([...sources, { url: "" }]); // account for next empty url input
      setOldUrls(newsletterSourceUrls);
    })
  }, []);

  return (
    <div>
      <h1>Sources</h1>

      <div className="info-bubble">
        💡
        <span style={{marginLeft: "8px"}}>
          Specify websites you'd like us to regularly visit to curate content.
        </span>
        <br />
        <br />
        <div style={{paddingLeft: "22px"}}>
          Good examples include:
          <ul style={{marginTop: "8px", marginBottom: "4px"}}>
            <li>An events page for your industry</li>
            <li>Your company's jobs page</li>
            <li>News sources and blogs</li>
          </ul>
        </div>
      </div>

      {updatedSourcesSuccess && (
        <div
          style={{
            backgroundColor: "#e0f7df",
            color: "#2e7d32",
            border: "1px solid #2e7d32",
            borderRadius: "8px",
            padding: "8px 16px",
            marginBottom: "8px",
            maxWidth: "500px",
          }}>
          ✅
          <span style={{marginLeft: "12px"}}>Sources successfully updated!</span>
        </div>
      )}

      <UrlInputForm
        urlObjects={urlObjects}
        handleUrlsChange={handleUrlsChange}
        oldUrls={oldUrls}
        placeholder="https://techcrunch.com/events"
        instructions={true}
      />

      <button
        className="primary-button"
        onClick={_ => updateNewsletterSources(urlObjects)}
      >
        Save
      </button>

      <DisclosureText />
    </div>
  );
};

export default Sources;
