import React, { useState } from "react";
import campaignService from "../services/campaignService";

export interface CustomContentFormProps {
  newsletterEditionId: string;
  setSidePanelOpen: (open: boolean) => void;
  setSubmitSuccess: (success: boolean) => void;
}

const CustomContentForm: React.FC<CustomContentFormProps> = ({
  newsletterEditionId,
  setSidePanelOpen,
  setSubmitSuccess
}) => {
  const [text, setText] = useState("");

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  const submitText = async (text: string) => {
    try {
      await campaignService.submitNewsletterEditionCustomContent(
        newsletterEditionId,
        text,
      );
      setText("");
      setSidePanelOpen(false);
      setSubmitSuccess(true);
    } catch (e) {
      console.error("Failed to submit text", e);
    }
  }

  return (
    <div>
      <h1>
        Custom content
      </h1>

      <textarea
        value={text}
        onChange={handleChange}
        placeholder="Add custom text..."
        rows={24}
        cols={30}
        className="feedback-textarea-input"
      />

      <button
        className="primary-button"
        onClick={() => submitText(text)}
      >
        Submit
      </button>
    </div>
  );
};

export default CustomContentForm;
