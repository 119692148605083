import React from "react";
import { useNavigate } from "react-router-dom";
import Joyride from "react-joyride";

const steps = [
  {
    target: '#Sidebar-Blueprint',
    content: 'Edit the blueprint you created to change how we fill out your newsletter each week',
    disableBeacon: true,
  },
  {
    target: '#walkthrough-add-links',
    content: 'Add links to include in this post',
  },
  {
    target: '#walkthrough-add-content',
    content: 'Add custom content that you\'d like to include in this post',
  },
  {
    target: '#walkthrough-arrival-date',
    content: 'The date our team will have the newsletter prepared by',
  },
  {
    target: '#Sidebar-Sources',
    content: 'Add sources for us to regularly visit and curate content',
  },
];

export interface OnboardingWalkthroughProps {
  run: boolean;
  setRun: (run: boolean) => void;
}

const OnboardingWalkthrough: React.FC<OnboardingWalkthroughProps> = ({ run, setRun }) => {
  const navigate = useNavigate();

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous
      showProgress
      showSkipButton
      disableOverlayClose={true}
      styles={{
        options: {
          zIndex: 10000,
        },
        buttonNext: {
          backgroundColor: '#5D5DED',
          color: '#ffffff',
          borderRadius: "8px",
        },
        buttonBack: {
          color: '#5D5DED',
        },
        beaconInner: {
          backgroundColor: '#5D5DED',
        },
        beaconOuter: {
          backgroundColor: '#6F6FFE',
          boxShadow: '0 0 15px rgba(93, 93, 237, 0.6)',
          borderColor: '#5D5DED',
        }
      }}
      callback={data => {
        if (data.status === 'finished' || data.status === 'skipped') {
          setRun(false);
          navigate('/sources');
        }
      }}
    />
  );
};

export default OnboardingWalkthrough;
