import React, { useState } from "react";
import { useParams } from "react-router-dom";
import campaignService from "../services/campaignService";

export interface GetFeedbackFormProps {
  setSidePanelOpen: (open: boolean) => void;
  setSubmitSuccess: (success: boolean) => void;
}

const GiveFeedbackFrom: React.FC<GetFeedbackFormProps> = ({ setSidePanelOpen, setSubmitSuccess }) => {
  const { newsletterEditionId } = useParams();

  const [text, setText] = useState("");

  const handleChange = (e: any) => {
    setText(e.target.value);
  }

  const submitFeedback = async (comment: string) => {
    try {
      await campaignService.submitNewsletterEditionFeedback(
        newsletterEditionId!,
        comment,
      );
      setText("");
      setSidePanelOpen(false);
      setSubmitSuccess(true);
    } catch (e) {
      console.error("Failed to submit feedback", e);
    }
  }

  return (
      <div>
          <h1>Request Changes</h1>
          <p>This is a space where you can make requests for changes and our team will address them within 24 hours.</p>
          <p>You can request design changes, content changes, or give any other feedback.</p>

          <textarea
              value={text}
              onChange={handleChange}
              placeholder="Request changes..."
              rows={8}
              cols={30}
              className="feedback-textarea-input"
          />

          <button
              className="primary-button"
              onClick={() => submitFeedback(text)}>
              Submit
          </button>
      </div>
  );
};

export default GiveFeedbackFrom;
