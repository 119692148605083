import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserDetails, selectUserId, UserDetails } from "../store/slices/authUserSlice";
import { createSelector } from "@reduxjs/toolkit";
// statsig
import { IS_PRODUCTION, STATSIG_API_KEY } from "../services/environmentService";
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { StatsigSessionReplayPlugin } from "@statsig/session-replay";
// import { StatsigAutoCapturePlugin } from '@statsig/web-analytics';

export const selectStatsigUser = createSelector(
    [selectUserId, selectUserDetails],
    (
      userId?: string,
      userDetails?: UserDetails,
    ): {userID: string; email?: string;} => ({
      userID: userId ?? "",
      email: userDetails?.email,
    }),
  );

interface StatsigMetricsProviderProps {
  children: React.ReactNode;
}

/**
 * Configure metrics provider. We're using Statsig.
 */
const StatsigMetricsProvider: React.FC<StatsigMetricsProviderProps> = ({ children }) => {
    const environmentTier = IS_PRODUCTION ? 'production' : 'development';
    const statsigUser = useSelector(selectStatsigUser);

    let { client } = useClientAsyncInit(
      STATSIG_API_KEY,
      statsigUser,
      {
        environment: { tier: environmentTier },
        plugins: [
          new StatsigSessionReplayPlugin(),
          // disable for now - captures clicks, page views, and performance
          // new StatsigAutoCapturePlugin(),
        ],
      }
    );

    // hello world for statsig
    client.logEvent("initialized_statsig_client", "true");

    useEffect(() => {
      client.updateUserAsync(statsigUser);
    }, [statsigUser])

  return (
    <StatsigProvider client={client}>{children}</StatsigProvider>
  );
};

export default StatsigMetricsProvider;
