// src/components/Onboarding/steps/EmailStep.tsx
import React, {useRef, useEffect} from "react";
import {OnboardingFormProps} from "../Types";
import flyingPaperPlaneGif from "../../../assets/animations/flying_paper_plane.gif";

const ThanksStep: React.FC<OnboardingFormProps> = ({formData, onChange}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div
        // className="primary-input-container"
    >
      <h1 style={{ marginTop: "0.25em" }}>Your Newsletter Is On The Way!</h1>

      <div
          className="primary-input-container"
          style={{
            maxWidth: "528px",
            lineHeight: "1.5",
            fontSize: "1.1em",
          }}
      >
        <img src={flyingPaperPlaneGif} alt="Flying paper plane"/>
        <p>
          We'll notify you in at most 48 hours with your first newsletter.

          Until then, feel free to explore the platform to see how it works.
        </p>
        <p>Email evan@aimply.io with any questions.</p>
      </div>
    </div>
  );
};

export default ThanksStep;
