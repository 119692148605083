import React from 'react';
import {ReactComponent as InfoIcon} from "../assets/icons/Info.svg";

interface InfoButtonProps {
    text: string;
    tooltipText: string;
    style?: React.CSSProperties;
}

const ToolTipButton: React.FC<InfoButtonProps> = ({ text, tooltipText, style }) => {
    return (
        <div className="tooltip-container" style={{ position: "relative", display: "inline-block" }}>
            <button style={style} className="tertiary-info-button">
                <span style={{ display: "flex", alignItems: "center" }}>
                    {text}
                    <InfoIcon style={{ marginLeft: "8px" }} />
                </span>
            </button>
            <div className="tooltip">
                {tooltipText}
            </div>
        </div>
    );
};

export default ToolTipButton;