import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {NewsletterEdition} from "../types/newsletterEdition";
import campaignService from "../services/campaignService";
import SidePanel from "./SidePanel";
import EditionLinks from "./EditionLinks";
import ToolTipButton from "./ToolTipButton";
import CustomContentForm from "./CustomContentForm";
import OnboardingWalkthrough from "./Onboarding/steps/OnboardingWalkthrough";
import { ReactComponent as TrashIcon } from  "../assets/icons/trash.svg";

/**
 * Call the API to fetch all newsletter editions that belong to the current user.
 */
const fetchNewsletterEditions = async (): Promise<NewsletterEdition[]> => {
  return campaignService.listNewsletterEditions();
};

const toRecurringRepresentation = (num: number) => {
  if (num === 1) return "Once";
  if (num === 2) return "Twice";
  return `${num} times`;
};

const Posts: React.FC = () => {
  const navigate = useNavigate();
  const [newsletterEditions, setNewsletterEditions] = useState<
    NewsletterEdition[]
  >([]);

  const [isLinksSidePanelOpen, setIsLinksSidePanelOpen] = useState(false);
  const [isTextSidePanelOpen, setIsTextSidePanelOpen] = useState(false);
  const [activeEditionId, setActiveEditionId] = useState("");

  const [intervalUnit, setIntervalUnit] = useState("");
  const [intervalValue, setIntervalValue] = useState(0);

  const deleteNewsletterEdition = async (
    newsletterEditionId: string,
  ): Promise<any> => {
    await campaignService.deleteNewsletterEdition(newsletterEditionId);
    // fetch the updated list of newsletter editions after deletion
    fetchNewsletterEditions().then(setNewsletterEditions);
  };

  useEffect(() => {
    fetchNewsletterEditions().then(setNewsletterEditions);
  }, []);

  if (newsletterEditions.length > 0) {
    // today, scheduling fields live on individual editions
    const edition = newsletterEditions[0];
    if (!intervalUnit && !intervalValue) {
      setIntervalUnit(edition.interval_unit!);
      setIntervalValue(edition.interval_value!);
    }
  }

  const [submitCustomContentSuccess, setSubmitCustomContentSuccess] = useState(false);
  const handleSubmitCustomContentSuccess = (success: boolean) => {
    if (success) {
        setSubmitCustomContentSuccess(true);

        // clear success banner so it's not sticky
        setTimeout(() => {
            setSubmitCustomContentSuccess(false);
        }, 2000);
    } else {
        setSubmitCustomContentSuccess(false);
    }
  }

  const location = useLocation();
  const onboardingStatus = location.state?.onboardingStatus;
  const [showWalkthrough, setShowWalkthrough] = useState(onboardingStatus === "complete");

  return (
      <div>
          <div
              style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
              }}>
              <h1 style={{display: "inline-block"}}>Posts</h1>

              <div style={{display: "inline-block", marginLeft: "auto"}}>
                  <ToolTipButton
                      text={`${toRecurringRepresentation(intervalValue)} per ${intervalUnit}`}
                      style={{marginRight: "10px"}}
                      tooltipText="The frequency our team will have the newsletter prepared. Email evan@aimply.io to change."
                  />
                  <button
                      type="submit"
                      className="primary-button"
                      onClick={() => navigate("/newsletterEdition/new")}
                  >
                      Create manual post
                  </button>
              </div>
          </div>
          <p style={{marginTop: "0"}}>
              Create new posts, view existing posts, and edit templates.
          </p>

          { submitCustomContentSuccess &&
            <div
                style={{
                    backgroundColor: "#e0f7df",
                    color: "#2e7d32",
                    border: "1px solid #2e7d32",
                    borderRadius: "8px",
                    padding: "8px 16px",
                    marginBottom: "12px",
                    maxWidth: "500px",
                }}
            >
                ✅
                <span style={{marginLeft: "12px"}}>Custom content submitted!</span>
            </div>
          }


          <ul className="newsletter-list">
              {newsletterEditions.map(newsletterEdition => (
                  <li
                      key={newsletterEdition.id}
                      className="newsletter-item"
                      onClick={() =>
                          navigate(`/newsletterEdition/${newsletterEdition.id}`)
                      }>

                      <div className="newsletter-item-content-left">
                        <span className="newsletter-title">
                            {newsletterEdition.title}
                        </span>

                        <span className="newsletter-status">
                            {newsletterEdition.status}
                        </span>
                      </div>

                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}>
                        <span
                            id="walkthrough-arrival-date"
                            className="list-tag-gray"
                        >
                            Arrival:{" "}
                            {new Date(
                                newsletterEdition.newsletter_timestamp,
                            ).toLocaleDateString()}
                        </span>

                        <button
                            id="walkthrough-add-content"
                            className="tertiary-button"
                            style={{
                                height: "36px",
                                border: "1px solid #5D5DED",
                                borderRadius: "10px",
                                minWidth: "88px",
                            }}
                            onClick={e => {
                                e.stopPropagation(); // short-circuit the parent's `onClick` behavior
                                setIsTextSidePanelOpen(true);
                                setActiveEditionId(newsletterEdition.id);
                            }}
                        >
                            Add content
                        </button>

                        <button
                            id="walkthrough-add-links"
                            className="tertiary-button"
                            style={{
                                height: "36px",
                                border: "1px solid #5D5DED",
                                borderRadius: "10px",
                                minWidth: "88px",
                            }}
                            onClick={e => {
                                e.stopPropagation(); // short-circuit the parent's `onClick` behavior
                                setIsLinksSidePanelOpen(true);
                                setActiveEditionId(newsletterEdition.id);
                            }}>
                            Add links
                        </button>

                        <span>
                            <TrashIcon
                                aria-label="Delete"
                                style={{
                                    width: "2em",
                                    height: "2em",
                                    marginRight: "12px",
                                    cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation(); // short-circuit the parent's `onClick` behavior

                                    const confirmed = window.confirm(
                                        "Are you sure you want to delete this post? This action cannot be undone."
                                    );

                                    if (confirmed) {
                                        deleteNewsletterEdition(newsletterEdition.id);
                                    }
                                }}
                            />
                        </span>
                      </div>
                  </li>
              ))}
          </ul>

          <SidePanel isOpen={isLinksSidePanelOpen} onClose={() => setIsLinksSidePanelOpen(false)}>
              <EditionLinks newsletterEditionId={activeEditionId}/>
          </SidePanel>

          <SidePanel isOpen={isTextSidePanelOpen} onClose={() => setIsTextSidePanelOpen(false)}>
              <CustomContentForm
                newsletterEditionId={activeEditionId}
                setSidePanelOpen={setIsTextSidePanelOpen}
                setSubmitSuccess={handleSubmitCustomContentSuccess}
              />
          </SidePanel>

          <OnboardingWalkthrough run={showWalkthrough} setRun={setShowWalkthrough} />
      </div>
  );
};

export default Posts;
