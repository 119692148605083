import React from "react";
import {logout} from "../../services/authService";
import {useNavigate} from "react-router-dom";
import Notification from "./Notification";
import {ReactComponent as Logo} from "../../assets/icons/logo-full.svg";
import {selectAuthenticated} from "../../store/slices/authUserSlice";
import { useSelector } from "react-redux";

const Header: React.FC = () => {
  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectAuthenticated);

  const handleLogout = async () => {
    await logout()
    .catch((error: any) => {console.log("logout failed", error)})
    .finally(() => navigate("/login"))
  };

  return (
    <header>
      <div
        style={{
          padding: "5px",
          display: "flex",
          alignItems: "center",
          borderBottom: "5px solid black",
        }}>
        <a href="/dashboard">
          <Logo
            style={{
              padding: "1em",
              width: "100px",
              height: "100%",
            }}
          />
        </a>
        <nav style={{marginLeft: "auto", padding: "0 20px"}}>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              gap: "10px",
              margin: 0,
              alignItems: "center",
            }}>
            <li>
              <Notification />
            </li>
            <button type="button" className="header-button"
              onClick={() => navigate("/help")}
            >
              Help
            </button>
            {isAuthenticated ? (
              <li>
                <button
                  type="button"
                  className="header-button"
                  onClick={handleLogout}>
                  Logout
                </button>
              </li>
            ) : (
              <>
                <li>
                  <button
                    type="button"
                    className="header-button"
                    onClick={() => navigate("/login")}>
                    Login
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="header-button"
                    onClick={() => navigate("/signup")}>
                    Sign up
                  </button>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
