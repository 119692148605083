import React from "react";
import { OnboardingFormProps } from "../Types";
import Blueprint from "../../Blueprint";

const BlueprintStep: React.FC<OnboardingFormProps> = ({ formData, onChange }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Blueprint />
    </div>
  );
};

export default BlueprintStep;
