import {DetailCheckboxOption} from "./Types";

export const SAMPLE_EVENTS_CIO = [
  {
    value:
      "Join us for an after work happy hour with the NYC AI community, ElevenLabs and Air Street Capital.",
    source: "https://lu.ma/aimixernyc?tk=YMY6YU",
    label: "Air Street x ElevenLabs NYC AI happy hour",
    tags: ["AI", "Artificial Intelligence"],
  },
  {
    value:
      "Join the NY Enterprise Tech Meetup to learn from top product leaders in enterprise software, featuring insights on accelerating product growth through velocity, collaboration, and experimentation.",
    source: "https://lu.ma/NYETMOctober24?tk=xXQkYl",
    label:
      "NY Enterprise Tech Meetup: How Ramp & Figma Built World-Class Products",
    tags: ["Enterprise Tech"],
  },
  {
    value:
      "NextView Ventures is hosting the Future Founder Soirée, an early evening happy hour aimed at aspiring entrepreneurs seeking co-founders, accountability partners, and inspiration.",
    source: "https://lu.ma/ub3bnrfy?tk=BnMOTA",
    label: "NextView Ventures Future Founder Soirée",
    tags: ["Startups", "Early Stage Founders"],
  },
  {
    value:
      "The AI Furnace hosted a GenAI Companies showcase in NYC, featuring demos from innovative startups Artisan AI and Velvet.",
    source: "https://lu.ma/yvg8qq5m?tk=kWzsfD",
    label: "NYC 🗽 GenAI Companies Building in NYC",
    tags: ["AI", "Startups"],
  },
];

// NOTE: I used max 45 words for these
export const SAMPLE_NEWS_CIO = [
  {
    value:
      "As generative AI reshapes workplaces, organizations are encouraged to shift from traditional job title-based hiring to a skills-based approach, focusing on adaptable skillsets to remain agile. This transition will enable companies to leverage AI efficiently.",
    label: "CIOs prioritize reskilling employees to embrace AI",
    source:
      "https://www.cio.com/article/2509754/generative-ai-and-preparing-for-a-shift-to-skills-based-hiring.html",
    tags: ["AI"],
  },
  {
    value:
      "Nvidia is expanding AI operations in India, partnering with major tech firms like Infosys and Tata Communications to boost data center capacity and train 500,000 developers. This push leverages India's IT ecosystem for AI applications.",
    label: "Nvidia Taps India's Growing AI Market",
    source: "",
    tags: ["AI", "Semiconductors"],
  },
  {
    value:
      "Apple unveiled Apple Intelligence at WWDC 2024, integrating AI into existing products rather than creating standalone features. The platform will enhance apps like Messages and Siri with features like text generation and image creation.",
    label: "What is Apple Intelligence, when is it coming and who will get it?",
    source: "",
    tags: ["AI"],
  },
  {
    value:
      "The U.S. Cybersecurity and Infrastructure Security Agency (CISA) has flagged a high-severity vulnerability in Microsoft SharePoint (CVE-2024-38094), actively exploited by attackers to achieve remote code execution.",
    label:
      "CISA highlights active exploitation of Microsoft SharePoint vulnerability",
    source:
      "https://thehackernews.com/2024/10/cisa-warns-of-active-exploitation-of.html",
    tags: ["Software Security", "Vulnerabilities", "Microsoft"],
  },
];

// TODO - I'm actually not going to show the community content in the demo, so this is just a placeholder
//  We need to figure out a clear way to show types of community content in the demo - I'm not sure it's the same as news/jobs/events
export const COMMUNITY_CONTENT_CIO = [
  {
    value:
      "The rapid evolution of AI technologies has changed the game for CIOs. With AI now embedded across various enterprise functions—from predictive maintenance to customer service—CIOs are expected to lead AI strategy discussions at the executive level.\n - Author: Jamie Martinez, Senior IT Consultant",
    label:
      "Community Submission: How Emerging AI Tools Are Reshaping the CIO Role",
    source: "",
    tags: ["AI"],
  },
  {
    value:
      "As our company grew, we needed a cloud infrastructure that could scale quickly. The consulting firm made our environment more agile, enabling us to handle business demands efficiently, without any performance or security compromises.\n - Author: John Locke, CIO",
    label: "Testimonial: Cloud Infrastructure",
    source: "",
    tags: ["Cloud Infrastructure"],
  },
  {
    value:
      "Implementing AI tools like ChatGPT in the workplace offers significant potential, but safety and data security remain paramount. We have a responsibility to ensure that any AI tool we adopt complies with our data protection policies and aligns with our business objectives.\n - Author: Michael Smith, CIO",
    label: "Q&A: Do you think it's safe to have ChatGPT in your workplace?",
    source: "",
    tags: ["AI", "IT"],
  },
  {
    value:
      "Thank you for inviting me to contribute commentary on such a timely topic. AI is rapidly transforming how we approach both everyday tasks and strategic goals in the workplace, and I’m excited to share my perspective.\n - Author: Dana Jackson, CIO",
    label: "Event Commentary: AI in the Workplace",
    source: "",
    tags: ["AI", "Events"],
  },
];

export const SAMPLE_JOBS_CIO = [
  {
    value:
      "The Chief Information Officer (CIO) will provide leadership, vision, and direction in the development and implementation of technology initiatives that align with the strategic goals of Mountain West Insurance & Financial Services.",
    label: "Chief Information Officer - Mountain West Insurance",
    source: "",
    tags: ["IT", "Leadership"],
  },
  {
    value:
      "Lead the strategy and execution for on-site personalization features (e.g. product recommendation), ensuring they deliver value from ideation through optimization.",
    label: "Lead Product Manager, Personalization - Attentive",
    source: "",
    tags: ["AI", "Product Management"],
  },
  {
    value:
      "The Chief Digital Information Officer plays a critical role in managing the technology infrastructure and systems that support the organization's clinical, financial, and administrative operations",
    label: "Chief Digital Information Officer - AMN",
    source: "",
    tags: ["IT", "Leadership"],
  },
  {
    value:
      "Java Lead/Sr. Software Engineer position in NY/NJ or LA, offering up to $140K annually. Responsibilities include backend development in Java/Scala, building CI/CD pipelines, and integrating automated incident response with PagerDuty.",
    label: "Java Lead/Sr. Software Engineer - Dice",
    source: "",
    tags: ["AI"],
  },
];

export const PREPPED_SAMPLE_DATA: Record<string, Record<string, DetailCheckboxOption[]>> = {
  // "evan@aimply.io": {
  //   events: SAMPLE_EVENTS_CIO,
  //   news: SAMPLE_NEWS_CIO,
  //   jobs: SAMPLE_JOBS_CIO,
  //   communityContent: COMMUNITY_CONTENT_CIO
  // },
  "jsmagacz@riskgroup.com": {
    events: [
      {
        label: "OSHA Compliance Training",
        source: "https://example.com/osha-training",
        tags: ["EHS", "Compliance"],
        value: "A comprehensive training session on OSHA compliance, covering essential safety and health regulations for office environments. Hosted by the National Safety Council.",
      },
      {
        label: "IFMA Facilities Management Conference",
        source: "https://example.com/ifma-conference",
        tags: ["Facilities Management", "Conference"],
        value: "An annual conference for facilities managers organized by the International Facility Management Association (IFMA), featuring expert speakers, networking opportunities, and the latest industry trends.",
      },
      {
        label: "Ergonomics in the Workplace Workshop",
        source: "https://example.com/ergonomics-workshop",
        tags: ["EHS", "Ergonomics"],
        value: "A workshop focused on improving workplace ergonomics, aimed at reducing strain and enhancing employee comfort and productivity. Conducted by the Ergonomics Center of North Carolina.",
      },
      {
        label: "Sustainability in the Office Seminar",
        source: "https://example.com/sustainability-seminar",
        tags: ["Sustainability", "Office Management"],
        value: "A seminar exploring sustainable practices in office management, including energy efficiency, waste reduction, and green initiatives. Presented by the Green Business Bureau.",
      },
    ],
    news: [
      {
        label: "OSHA Updates Workplace Safety Guidelines for Office Environments",
        value: "OSHA has new guidelines to improve office safety, focusing on reducing slips, trips, and falls. These updates aim to create safer work environments for employees.",
        source: "https://example.com/osha-updates",
        tags: ["OSHA", "Workplace Safety", "Office Management"],
      },
      {
        label: "New Study Highlights the Importance of Indoor Air Quality for Employee Health",
        value: "A study shows that better indoor air quality improves employee health and productivity. Facilities managers are encouraged to enhance ventilation and reduce pollutants.",
        source: "https://example.com/indoor-air-quality",
        tags: ["Indoor Air Quality", "Employee Health", "Facilities Management"],
      },
      {
        label: "EPA Releases New Guidelines for Sustainable Facilities Management",
        value: "EPA's new guidelines focus on reducing energy use, conserving water, and reducing waste. These guidelines help create more sustainable work environments.",
        source: "https://example.com/sustainable-facilities",
        tags: ["Sustainable Facilities", "EPA", "Facilities Management"],
      },
      {
        label: "Emerging Trends in Facilities Management Technology",
        value: "Report on the latest trends in facilities management tech, including IoT and AI solutions. These technologies can improve operational efficiency and reduce costs.",
        source: "https://example.com/facilities-tech-trends",
        tags: ["Facilities Management", "Technology", "IoT"],
      },
    ],
    jobs: [
      {
        value: "The Office Manager will be responsible for overseeing the day-to-day operations of the office, including managing administrative staff, coordinating travel arrangements, and maintaining office supplies.",
        label: "Office Manager",
        source: "https://example.com/office-manager",
        tags: ["Office Management"],
      },
      {
        value: "The EHS Specialist will be responsible for ensuring compliance with environmental, health, and safety regulations, conducting risk assessments, and implementing safety protocols to prevent workplace injuries and illnesses.",
        label: "EHS Specialist",
        source: "https://example.com/ehs-specialist",
        tags: ["EHS"],
      },
      {
        value: "The Facilities Manager will be responsible for overseeing the maintenance and upkeep of company facilities, including managing budgets, coordinating repairs, and ensuring compliance with regulatory requirements.",
        label: "Facilities Manager",
        source: "https://example.com/facilities-manager",
        tags: ["Facilities Management"],
      },
      {
        value: "The Sustainability Coordinator will be responsible for developing and implementing sustainable practices and initiatives within the company, including reducing energy consumption, conserving water, and reducing waste.",
        label: "Sustainability Coordinator",
        source: "https://example.com/sustainability-coordinator",
        tags: ["Sustainability", "Facilities Management"],
      },
    ]
  },
  "rcavallito@gmail.com": {
    events: [
      {
        label: "Value Investing Conference",
        value: "Join us for a comprehensive conference on value investing, featuring expert speakers and panel discussions on the latest trends and strategies.",
        source: "https://example.com/value-investing-conference",
        tags: ["Value Investing", "Conference"],
      },
      {
        label: "Ben Graham Lecture Series",
        value: "A series of lectures on value investing principles and practices, inspired by the teachings of Benjamin Graham, the father of value investing.",
        source: "https://example.com/ben-graham-lectures",
        tags: ["Ben Graham", "Value Investing"],
      },
      {
        label: "Warren Buffett's Annual Shareholder Meeting",
        value: "Attend the annual shareholder meeting of Berkshire Hathaway, led by Warren Buffett, and gain insights into his investment strategies and philosophies.",
        source: "https://example.com/berkshire-hathaway-meeting",
        tags: ["Warren Buffett", "Berkshire Hathaway"],
      },
      {
        label: "Value Investing Workshop for Beginners",
        value: "A hands-on workshop for those new to value investing, covering the fundamentals of value investing and providing practical tips for getting started.",
        source: "https://example.com/value-investing-workshop",
        tags: ["Value Investing", "Beginners"],
      },
    ],
    news: [
      {
        label: "Warren Buffett's Latest Stock Picks",
        value: "Warren Buffett's Berkshire Hathaway has released its latest portfolio, revealing new stock picks and increased stakes in existing holdings. Value investors are taking note of the moves, looking for insights into the Oracle of Omaha's investment strategy.",
        source: "https://example.com/warren-buffett-stock-picks",
        tags: ["Warren Buffett", "Value Investing", "Stock Picks"],
      },
      {
        label: "The Rise of Activist Investing",
        value: "Activist investors are increasingly making headlines, pushing for changes at companies to unlock value for shareholders. Value investors are watching closely, looking for opportunities to benefit from these efforts.",
        source: "https://example.com/activist-investing",
        tags: ["Activist Investing", "Value Investing", "Shareholder Value"],
      },
      {
        label: "The Impact of ESG on Value Investing",
        value: "Environmental, social, and governance (ESG) factors are becoming increasingly important for value investors, as they look to balance returns with responsible investing practices. Learn how ESG is shaping the value investing landscape.",
        source: "https://example.com/esg-value-investing",
        tags: ["ESG", "Value Investing", "Responsible Investing"],
      },
      {
        label: "Deep Value Investing in a Volatile Market",
        value: "In a market marked by volatility, deep value investing can provide a compelling opportunity for investors looking to capitalize on undervalued companies. Learn how to identify and invest in these hidden gems.",
        source: "https://example.com/deep-value-investing",
        tags: ["Deep Value Investing", "Value Investing", "Volatile Market"],
      },
    ],
    jobs: [
      {
        label: "Value Investing Analyst",
        value: "Conduct in-depth analysis of financial statements, identify undervalued companies, and provide recommendations to portfolio managers.",
        source: "https://example.com/value-investing-analyst",
        tags: ["Value Investing", "Financial Analysis"],
      },
      {
        label: "Portfolio Manager - Value Investing",
        value: "Manage a portfolio of undervalued stocks, bonds, and other securities, with a focus on long-term capital appreciation.",
        source: "https://example.com/portfolio-manager-value-investing",
        tags: ["Value Investing", "Portfolio Management"],
      },
      {
        label: "Research Associate - Value Investing",
        value: "Assist in the research and analysis of potential investment opportunities, including financial modeling and data analysis.",
        source: "https://example.com/research-associate-value-investing",
        tags: ["Value Investing", "Research"],
      },
      {
        label: "Investment Banking Analyst - M&A",
        value: "Work on mergers and acquisitions, assisting in the analysis and execution of deals, with a focus on creating value for clients.",
        source: "https://example.com/investment-banking-analyst-ma",
        tags: ["Investment Banking", "M&A"],
      },
    ],
  },
  "twagner@lightscapepartners.com": {
    events: SAMPLE_EVENTS_CIO,
    news: [
      {
        label: "OpenAI Secures $1 Billion in New Funding",
        value: "OpenAI has raised an additional $1 billion in funding, with participation from top-tier VCs and strategic investors. The funds will fuel their expansion into enterprise AI tools and further innovation in generative AI technology.",
        source: "https://example.com/openai-funding-round",
        tags: ["Funding", "AI", "Startups"],
      },
      {
        label: "Notion Launches AI-Powered Workflows",
        value: "Notion has unveiled new AI-powered workflows, enabling startups and teams to automate repetitive tasks seamlessly. The product launch positions Notion as a leading productivity tool for VC-backed companies scaling their operations.",
        source: "https://example.com/notion-ai-launch",
        tags: ["Product Launch", "AI", "Productivity"],
      },
      {
        label: "Stripe Expands Into Lending",
        value: "Stripe has announced the launch of a new lending product aimed at small and medium-sized businesses. The move diversifies Stripe's offerings, making it an essential financial services partner for fast-growing startups.",
        source: "https://example.com/stripe-lending",
        tags: ["Fintech", "Product Launch", "Startups"],
      },
      {
        label: "Figma Acquired by Adobe in $20 Billion Deal",
        value: "Figma, the popular design collaboration tool, has been acquired by Adobe for $20 billion. The acquisition highlights the increasing importance of design tools for startups and their role in scaling modern SaaS businesses.",
        source: "https://example.com/figma-adobe-acquisition",
        tags: ["Acquisition", "Design", "SaaS"],
      },
    ],
    jobs: SAMPLE_JOBS_CIO
  },
  "kate.mccrohan@rho.co": {
    events: SAMPLE_EVENTS_CIO,
    news: [
      {
        label: "OpenAI Secures $1 Billion in New Funding",
        value: "OpenAI has raised an additional $1 billion in funding, with participation from top-tier VCs and strategic investors. The funds will fuel their expansion into enterprise AI tools and further innovation in generative AI technology.",
        source: "https://example.com/openai-funding-round",
        tags: ["Funding", "AI", "Startups"],
      },
      {
        label: "Notion Launches AI-Powered Workflows",
        value: "Notion has unveiled new AI-powered workflows, enabling startups and teams to automate repetitive tasks seamlessly. The product launch positions Notion as a leading productivity tool for VC-backed companies scaling their operations.",
        source: "https://example.com/notion-ai-launch",
        tags: ["Product Launch", "AI", "Productivity"],
      },
      {
        label: "Stripe Expands Into Lending",
        value: "Stripe has announced the launch of a new lending product aimed at small and medium-sized businesses. The move diversifies Stripe's offerings, making it an essential financial services partner for fast-growing startups.",
        source: "https://example.com/stripe-lending",
        tags: ["Fintech", "Product Launch", "Startups"],
      },
      {
        label: "Figma Acquired by Adobe in $20 Billion Deal",
        value: "Figma, the popular design collaboration tool, has been acquired by Adobe for $20 billion. The acquisition highlights the increasing importance of design tools for startups and their role in scaling modern SaaS businesses.",
        source: "https://example.com/figma-adobe-acquisition",
        tags: ["Acquisition", "Design", "SaaS"],
      },
    ],
    jobs: SAMPLE_JOBS_CIO
  },
}

