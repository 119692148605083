import React from "react";

const Settings: React.FC = () => {
  return (
      <div>
          <h1>Settings</h1>
          <div className="primary-input-container"
               style={{maxWidth: "600px"}}
          >
              <h3>Cancel Your Subscription</h3>
              <p>You can cancel your subscription <a href="https://billing.stripe.com/p/login/5kA7tpbg6cSJ8gwbII">logging
                  into your Stripe account here</a>.</p>
          </div>
      </div>
  );
};

export default Settings;
